'use client'

import { Error } from '@marketplace-web/shared/ui-helpers'

const title = 'Oops! Something went wrong.'
const body =
  "We apologize for the inconvenience, but it appears that we're experiencing some technical difficulties at the moment. Our team has already been alerted and is working to fix the issue. Thank you for your patience!"
const action = 'Reload'

type Props = {
  reset: () => void
}

const ErrorPage = ({ reset }: Props) => (
  <Error body={body} title={title} action={action} onActionClick={reset} />
)

export default ErrorPage
